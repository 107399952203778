export const policy = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <h1><strong>GDPR Compliance Policy</strong></h1>

    <p>&nbsp;</p>

    <h2><strong>Collection, Utilisation, and Security of Data</strong></h2>

    <p>
      ESGWize may in the course of its business only collects and processes essential data of the B2B companies,
      clients, and other users we interact with (Data Subjects). We don&rsquo;t collect or store any personal
      information of the user who is accessing our website which may hinder their privacy.
    </p>

    <p>What we Collect:</p>
    <br>
    <ul style="padding-left:20px">
      <li><strong>Log-in credentials</strong></li>
      <li><strong>Name, Phone Number &amp; Email Address of User and Client</strong></li>
      <li><strong>Name of Companies</strong></li>
      <li><strong>Environment, Social &amp; Governance (ESG) related performance details of a company</strong></li>
    </ul>
    <br>
    <p>
      The purpose of collection of this information is to allow companies to manage social, economic, and environmental
      decisions from one platform. The ESG-related information stored about the company will serve the objective of our
      website, which is to calculate that company&rsquo;s ESG score. Any data information that we collect is stored
      electronically on our database.
    </p>
    <br>
    <h2><strong>Infrastructure</strong></h2>

    <p>
      ESGWize utilizes cloud computing service offerings, primarily from Amazon Web Services (AWS) as the core building
      blocks of the ESGWize platform.&nbsp;&nbsp;
    </p>

    <p>
      The two entities of this website, the&nbsp;Admin Portal and Client Portal are supported by Elastic Compute Cloud
      (EC2) services from Amazon run the majority of the ESGWize platform, and provide a reliable, scalable, and secure
      way to process customer data.
    </p>

    <p><strong>Web servers</strong></p>

    <p>
      Secure, reliable, and cloud-based capacity from Amazon EC2 makes up the majority of our web server landscape. Web
      servers process customer data and deliver the application functionality to our users while interfacing with other
      parts of our Infrastructure.
    </p>

    <p><strong>Technology</strong></p>

    <p>
      The backend technology used here is AWS Lambda &ndash; Serverless Computing. The Frontend technology is AWS -
      Elastic Compute Cloud (EC2)
    </p>

    <ul style="padding-left:20px">
      <li>
        <strong>Databases</strong><br />
        Amazon DynamoDB is a fully managed, serverless, key-value NoSQL database designed to run high-performance
        applications at any scale.
      </li>
      <li>
        <strong>Master</strong><br />
        Stores data such as encrypted passwords (hashed and salted bcrypt) and authentication information for the
        different users. It also stores other metadata that enables trafﬁc routing.
      </li>
      <li>
        <strong>Customer data</strong><br />
        Stores all information admin or client input in ESGWize including user, client, company, and template details.
      </li>
      <li>
        <strong>User data</strong><br />
        Stores information related to user proﬁles such as name and email address. Also, stores details related to the
        company like its name, score details, supporting datafiles, mapped news, and portfolios.
      </li>
      <li>
        <strong>File storage</strong><br />
        Storage servers are Simple Storage Service (S3) from Amazon. This stores all types of file attachments and
        profile images. Attachments are any ﬁles uploaded to various ESGWize entities like company, template, portfolio,
        and user profiles directly from a computer.
      </li>
      <li>
        <strong>Datacenter Locations</strong><br />
        ESGWize offers storage of data in a specific AWS datacenter location as of now. Based upon European
        Infrastructure the Customer Data and most user data will be stored in the Frankfurt (Germany) AWS region.
      </li>
    </ul>

    <br>
    <p><strong>Data center security</strong></p>

    <p>ESGWize relies on AWS&rsquo;s Physical and Environmental controls.&nbsp;</p>

    <p>&nbsp;</p>

    <p><strong>Data security &ndash; Encryption</strong></p>

    <p>
      All user data stored in Amazon DynamoDB is fully encrypted at rest. DynamoDB encryption at rest provides enhanced
      security by encrypting all your data at rest using encryption keys stored in&nbsp;AWS Key Management Service (AWS
      KMS). This functionality helps reduce the operational burden and complexity involved in protecting sensitive data.
    </p>

    <p>
      Connections to&nbsp;app.esgwize.com&nbsp;are encrypted with 128-bit encryption and support TLS 1.2 and above.
      Connections are encrypted and authenticated using AES_128_GCM and use ECDHE_RSA as the key exchange mechanism.
      ESGwize supports forward secrecy and AES-GCM and prohibits insecure connections using RC4 or TLS 1.1 and below.
      Logins and sensitive data transfers are performed over TLS only.
    </p>

    <p>&nbsp;</p>

    <p><strong>Product Security Features</strong></p>

    <p>ESGWize provides users and admins with the necessary features to protect their data</p>

    <ul style="padding-left:20px">
      <li>
        &nbsp;<strong>Administrators</strong><br />
        Administrators (&ldquo;Admins&rdquo;) can manage, approve or reject Users, Clients, Companies, and other
        entities created in the ESGWize system. The Admins can remove or inactivate any user from the admin console.
      </li>
      <li>
        <strong>Login security</strong><br />
        The users will initially use a temporary password sent by the application to access the client portal.
        Subsequently, the user can set their own password and use those credentials to log in to the client portal.
      </li>
    </ul>

    <p>Similarly, the Admins will use their own ESGWize credentials to log in the Admin portal</p>

    <p>&nbsp;</p>

    <p><strong>Third-Party Integration</strong></p>

    <p>
      Currently, ESGWize is neither providing any API or platform to integrate with other third-party resources nor we
      are collecting any information from any third-party sources
    </p>

    <p>&nbsp;</p>
  </body>
</html>
`