import axios from 'axios';
import ApiConstants from './_apis_/ApiConstants';
import isNil from 'lodash/isNil';
import { setSession, setRefreshToken } from './utils/jwt';

const instance = axios.create({
  // baseURL: 'http://localhost:3001/dev'
  baseURL: ApiConstants.BASE_URL
});

export function setToken(token) {
  instance.defaults.headers.Authorization = token;
}

export function clearToken() {
  delete instance.defaults.headers['Authorization'];
}

export function getToken() {
  return instance.defaults.headers['Authorization'];
}

instance.interceptors.response.use((response) => responseHandler(response), errorResponseHandler);

function responseHandler(response) {
  return Promise.resolve(response);
}

function errorResponseHandler(error) {
  // check for errorHandle config
  console.log(error);

  return refreshToken(error);
}

async function refreshToken(error) {
  // const dispatch = useDispatch()
  const { config, response } = error;
  let originalRequest = config;
  if (response && response.status && response.data) {
    const { status, data } = response;
    console.log('Status.....', status);
    console.log('Error....', data);
    if (status === 500 && data && data?.message == 'jwt expired') {
      const refreshToken = window.localStorage.getItem('refreshToken')
      if (!isNil(refreshToken)) {
        const payload = { refreshToken: refreshToken };
        const response = await axios.post(ApiConstants.REFRESH_TOKEN, payload);
        // const response = await executeRequest('post', ApiConstants.REFRESH_TOKEN, payload);
        const access_token = response.data?.accessToken || '';
        setToken(access_token);
        setSession(access_token)
        // const responseData = { ...authInfo, access_token: access_token };
        // store.dispatch(storeLoginInfo(responseData));
        originalRequest.headers['Authorization'] = access_token;
        return api(originalRequest);
      }
    } else if (status === 501 && data && data?.message == 'Refresh Token not Valid.') {
    }
  }
  return Promise.reject(error);
}

export default instance;
