import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { policy } from '../../utils/policy'

export default function GDPRDialog({ onConfirm, onCancel, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
      <Dialog
        scroll={scroll}
        {...props}
      >
        <DialogTitle id="scroll-dialog-title">GDPR Compliance Policy</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
        <Box variant="div" dangerouslySetInnerHTML={{ __html: policy }}>

        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>Okay</Button>
        </DialogActions>
      </Dialog>
    );
}