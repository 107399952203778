import { ENV } from '../constants/AppConstants'

const BASE_URL = ENV.dev

const ApiConstants = {
  BASE_URL: BASE_URL,
  LOGIN: '/login',
  MY_ACCOUNT: '/myAccount',
  REGISTER: '/account/register',
  UPDATE_ACCOUNT: (userId) => `/client/${userId}`,
//   DELETE_COMPANY: (companyId) => `/delete/${companyId}`,
  USER_COMPANIES: '/getCompanyList',
  COMPANY_LIST: '/company/list',
  ADD_COMPANY: '/add',
  DELETE_COMPANY: '/removecompany',
  CREATE_DATAFILES: '/datafiles',
  UPDATE_PASSWORD: '/updatepassword',
  VIEW_COMPANY:(companyId) => `/clientcompany/${companyId}`,
  COMPANY_NEWS:(companyId) => `/listnewscompany/${companyId}`,
  HISTORICAL_DATA: "/archive",
  SUPPORTING_DATA_FILES: (companyCode, clientId) => `/datafiles/${companyCode}/${clientId}`,
  UPLOAD_SUPPORTING_DATA_FILES:  `/datafiles`,
  LIST_TEMPLATES: '/templates',
  VIEW_TEMPLATE:(templateId) =>  `/template/${templateId}`,
  DATE_WISE_ESG_SCORE: "/dateWisecompanyesgscore",
  FORGOT_PASSWORD: "/forgotpassword",
  GENERATE_PASSWORD: "/regenreatePassword",
  RISK_MONITORING: "/riskmonitor",
  LIST_PORTFOLIO: "/listportfolio",
  ADD_PORTFOLIO: "/portfolio",
  EDIT_PORTFOLIO:(portfolioId) => `/portfolio/${portfolioId}`,
  DELETE_PORTFOLIO:(portfolioId) => `/portfolio/${portfolioId}`,
  BULK_UPLOAD_PORTFOLIO: "/bulkuploadclient",
  VIEW_PORTFOLIO:(portfolioId) =>  `/portfolio/${portfolioId}`,
  ARCHIVE_PORTFOLIO: '/archiveportfolio',
  REFRESH_TOKEN: '/refreshToken',
  UPDATE_PASSWORD: "/updatepassword",
  MATERIALITY_METRICS: '/materiality',
  DATAFILE_READER:(id) => `/clentuploaded/${id}`  
};

export default ApiConstants;
